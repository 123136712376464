/**
 * Checks if a path contains a needle.
 * /foo/bar/baz contains /foo/bar and /foo/bar/baz
 * /foo/bar/baz does not contain /foo/bar/baz-qux
 *
 * @param path
 * @param needle
 */
export function pathContains(path?: string, needle?: string): boolean {
    if (!path || !needle) {
        return false
    }
    const pathSegments = path.split('/')
    const needleSegments = needle.split('/')

    for (let i = 0; i < needleSegments.length; i++) {
        if (needleSegments[i] !== pathSegments[i]) {
            return false
        }
    }

    return true
}
